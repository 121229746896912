import React, { FC } from "react";

import { NavBar } from "components/molecules/navbarFB";
import { Layout } from "components/template/layout2";
import { NavItem } from "components/molecules/navbarFBItem";

import { ReactComponent as Instagram } from "src/assets/icons/socials/instagram.svg";
import { ReactComponent as Discord } from "src/assets/icons/socials/discord.svg";
import { ReactComponent as Medium } from "src/assets/icons/socials/medium.svg";
import { ReactComponent as Linkedin } from "src/assets/icons/socials/linkedin.svg";
import { ReactComponent as Twitter } from "src/assets/icons/socials/twitter.svg";
import { ReactComponent as Down } from "src/assets/icons/chevron-down.svg";

import "src/css/index.css";

const MindmapPage: FC = () => {
  return (
    <Layout>
      <NavBar>
        <NavItem icon={<Instagram stroke="var(--red)" strokeWidth=".5" />} />
        <NavItem icon={<Discord stroke="var(--red)" strokeWidth=".5" />} />
        <NavItem icon={<Medium stroke="var(--red)" strokeWidth=".5" />} />
        <NavItem icon={<Linkedin stroke="var(--red)" strokeWidth=".5" />} />
        <NavItem icon={<Twitter stroke="var(--red)" strokeWidth=".5" />} />
        <NavItem icon={<Down stroke="var(--red)" strokeWidth=".5" />}>
          {/* Dropdown does here */}
          <DropdownMenu />
        </NavItem>
        <NavItem icon="😊" />
      </NavBar>
    </Layout>
  );
};

export default MindmapPage;

function DropdownMenu() {
  const DropdownItem: FC<NavItemProps> = (props: NavItemProps) => {
    return (
      <a href="#" className="menu-item">
        {props.children}
      </a>
    );
  };

  interface NavItemProps {
    children?: JSX.Element | JSX.Element[];
  }

  return (
    <div className="dropdown">
      <DropdownItem>
        <div>bonjour</div>
      </DropdownItem>
    </div>
  );
}
