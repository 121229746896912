import styled from "styled-components";
import React, { FC } from "react";

const NavBarContainer = styled.div`
  height: var(--nav-size);
  padding: 0 1rem;
  border-bottom: var(--border);

  /* Ilyass Tests */
  background-color: var(--blue);
`;

const NavBarList = styled.ul`
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;

  /* Ilyass Tests */
  background-color: var(--yellow);
`;

export const NavBar: FC<Props> = ({ children }) => {
  return (
    <NavBarContainer>
      <NavBarList>{children}</NavBarList>
    </NavBarContainer>
  );
};

type Props = {
  children: JSX.Element | JSX.Element[];
};
