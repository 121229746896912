import styled from "styled-components";
import React, { FC, ReactNode, useState } from "react";

const NavBarItem = styled.li`
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  /* Ilyass Tests */
  background-color: var(--red);
`;

const NavBarLink = styled.a`
  color: var(--text-color);
  text-decoration: none;
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;

  /* Center everything */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Transition on hover */
  transition: filter 300ms;

  /* Ilyass Tests */
  background-color: var(--yellow);

  &:hover {
    filter: brightness(1.2);
  }
`;

export const NavItem: FC<NavItemProps> = function (props: NavItemProps) {
  const [open, setOpen] = useState(false);

  return (
    <NavBarItem>
      <NavBarLink href="#" onClick={() => setOpen(!open)}>
        {props.icon}
      </NavBarLink>
      {open && props.children}
    </NavBarItem>
  );
};

interface NavItemProps {
  icon?: ReactNode;
  children?: JSX.Element | JSX.Element[];
}
