import React, { FC } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { I18nextProvider } from "react-i18next";

import { IlyassCard } from "components/molecules/ilyassCard";
import { NavBar } from "components/molecules/navbarFB";
import { theme } from "components/theme";
import i18next from "src/i18n/config";

import "src/css/typography.css";
import "src/css/index.css";

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    font-family: "Post Grotesk", sans-serif;
  }
  body {
    color: var(--text-color);
    background:  var(--bg);
    text-rendering: optimizeLegibility;
  };
 
`;

export const Layout: FC<Props> = ({ children }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <>
          <NavBar>{children}</NavBar>
          <IlyassCard />
        </>
      </ThemeProvider>
    </I18nextProvider>
  );
};

type Props = {
  children: JSX.Element | JSX.Element[];
};
