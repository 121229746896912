import styled from "styled-components";
import React from "react";

const Container = styled.div`
  /* Variable definition */
  --size: 200px;

  /* Position */
  position: absolute;
  top: calc(50% - var(--size) / 2);
  left: calc(50% - var(--size) / 2);

  /* Size */
  width: 200px;
  height: 200px;

  /* BackgroundColor */
  border-radius: 5%;
  background-color: #eeeeee;
`;

export const IlyassCard = () => {
  return <Container>Bonjour</Container>;
};
